.customer_outer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 200px;
}

.customer{
  max-height: 150px;
  max-width:  250px;
}

.all_customers{
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 100px;
}

.three_customers{
  display: flex;
}

@media only screen and (max-width: 992px) {
  .customer{
    max-height: 150px;
    max-width:  200px;
  }
}

@media only screen and (max-width: 768px) {
  .three_customers{
    flex-direction: column;
  }
  .customer_outer{
    margin: 30px 0px;
    height: auto;
  }
}
