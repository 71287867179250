.all_services{
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  margin-top: -30px;
}

.three_services{
  display: flex;
}

.four_services{
  display: flex;
}

.service_outer{
  display: flex;
  align-items: center;
  flex: 1;
  height: 200px;
}

.service{
  font-size: 25px;
  color: #FFD593;
  font-weight: 500;
  width: 250px;
  font-style: italic;
}
@media only screen and (max-width: 992px) {
  .service{
    font-size: 20px;
    width: 200px;
  }
}

@media only screen and (max-width: 768px) {
  .all_services{
    margin-top: 10px;
  }
  .three_services{
    flex-direction: column;
  }
  .four_services{
    flex-direction: column;
  }
  .service_outer{
    justify-content:center !important;
    padding: 15px 0px;
    height: auto;
  }
}
