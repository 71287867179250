.contacts{
  display: flex;
}
.contact{
  flex: 1;
  font-size: 20px;
  font-weight: 300;
  color: #FFD593;
  margin-top: 50px;
  margin-bottom: 50px;
}
.contact a{
  transition: 0.2s;
}

.contact a:hover{
  background-color: #8DFF82;
  padding: 0px 10px;
  color: #292929;
}

.contact_img{
  height: 150px;
  width: 150px;
  border-radius: 150px;
}


.contact_text{
  font-size: 20px;
  letter-spacing: 1px;
  text-align: justify;
  line-height: 40px;
  color: #FFD593;
  font-weight: 300;
  margin: 50px 0px 0px 0px;
}

@media only screen and (max-width: 992px) {
  .contact_text{
    padding: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .contact_text{
    font-size: 15px;
    text-align: left;
  }
  .contact{
    font-size: 15px;
    margin: 10px 0px;
  }
  .contacts{
    flex-direction: column;
  }
}
