.head{
  width: 100%;
  font-style: italic;
  font-size: 100px;
  font-weight: 900;
  text-align: right;
  position: relative;
  line-height: 90px;
  height: 180px;
}

.head_top{
  position: absolute;
  color: #8DFF82;
  right: 10px;
}

.head_bot{
  position: absolute;
  color: #FFD593;
  right: 1px;
}

@media only screen and (max-width: 768px) {
  .head{
    font-size: 50px;
    font-weight: 900;
    margin-top: 10px;
    line-height: 40px;
    height: 80px;
  }
  .head_top{
    right: 10px;
  }
  .head_bot{
    right: 5px;
  }
}
