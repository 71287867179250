.intro_text{
  font-size: 20px;
  letter-spacing: 1px;
  text-align: justify;
  line-height: 40px;
  color: #FFD593;
  font-weight: 300;
  margin: 100px 0px;
}

@media only screen and (max-width: 992px) {
  .intro_text{
    padding: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .intro_text{
    font-size: 15px;
    text-align: left;
  }
}
