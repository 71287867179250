* {
    margin: 0px;
    padding: 0px;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #292929;
  width: 60%;
  margin: 0px auto;
  text-align: center;
}

a{
  text-decoration: none;
  color: #FFD593;
}

@media only screen and (max-width: 768px) {
  body{
    width: 100%;
  }
}

.p5Canvas{
  z-index: -1;
  position:fixed;
  top: 0px;
  left: 0px;
}
