.section_head{
  font-weight: 900;
  font-size: 30px;
  font-style: italic;
  /* background-color: #8DFF82; */
  display: table;
  color: #292929;
  padding: 10px 20px;
  margin: 0px auto;
  text-transform: uppercase;
  /* background-image: linear-gradient(to right, red , yellow); */
  /* background-image: linear-gradient(to right, #8DFF82, #FFD593); */
  background-image: linear-gradient(to right, #FFD593, #8DFF82);
}

@media only screen and (max-width: 768px) {
  .section_head{
    font-size: 20px;
    max-width: 200px;
  }
}
